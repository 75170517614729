
@keyframes fadeAnimasi {
    from {
        transform: translateY(-20px);
        opacity: 0; /* Awal transparan */
    }
    to {
        transform: translateY(0);
        opacity: 1; /* Akhir solid */
    }
}

@keyframes fadeAnimasiUp {
    from {
        transform: translateY(20px);
        opacity: 0; /* Awal transparan */
    }
    to {
        transform: translateY(0);
        opacity: 1; /* Akhir solid */
    }
}

@keyframes AnimasiUp {
     to {
        transform: translateY(-20px);
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0.1);
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes bounceOut {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes bounceIncard {
    0% {
        transform: scale(0);
        opacity: 0; /* Awal transparan */
    }
    70% {
        transform: scale(1);
        opacity: 1; /* Awal transparan */
    }
    100% {
        opacity: 1; /* Akhir solid */
    }
}


/* Header */
.Leanding-header{
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url('./../img/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
}

.content-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    box-sizing: border-box;
}

.close-menu{
    animation: bounceOut 1s ease-in-out;
    transform: scale(0);
    display: none;
}

.form-header{
    display: flex;
    justify-content: center;
    animation: fadeAnimasi 1s ease-in-out;
}

.button-plus, .button-min{
    font-size: 14px;
    background-color: #ffffff;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

.button-plus:hover, .button-min:hover{
    background-color: #f0f0f0;
    
}

.menu-header{
    margin-top: 5rem;
    text-decoration: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border: 1px solid #ffffff00;
    animation: bounceIn 1s ease-in-out;
}

.menu-header:hover{
    border: 1px solid #ededed;
    border-radius: 25px;
    cursor: pointer;
}

.text-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    animation: fadeAnimasi 1s ease-in-out;
}

.top-header{
    height: 60px;
    background-color: #00A2AD;
    position: relative;
    top: -40px;
    width: 20%;
    height: 60px;
    border-bottom-left-radius: 40px;
    transform: rotate(-10deg);
    left: 40%;
}

.footer-header{
    bottom: 0;
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
}

.input-budget:focus{
	outline: none;
}

/* Desa Wisata */
.Leanding-content-desawisata{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0 10%;
    box-sizing: border-box;
}

.desawisata-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.card-desawisata{
    margin: 10px;
    display: flex;
    background-color: #00A2AD;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    width:  calc(33.33% - 20px);
    height: 200px; 
    background-size: cover;
    transition: opacity 0.5s ease; /* Transisi untuk animasi */
    opacity: 0; /* Set opasitas awal ke 0 */
    position: relative;
    cursor: pointer;
    text-decoration: none;
}

.card-desawisata img {
    position: absolute; /* Atur posisi absolut untuk elemen gambar */
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
    z-index: 1; /* Pastikan gambar berada di bawah teks */
    transition: transform 0.3s ease;
}

.card-desawisata .content-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 200px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    background-color: #0000004d;/* Jaga proporsi gambar */
    z-index: 2; /* Pastikan gambar berada di bawah teks */
    transition: transform 0.3s ease;
}

.card-desawisata .content-card div .title-card {
    opacity: 0; /* Set opasitas awal ke 0 */
    transition: opacity 0.5s ease; /* Transisi untuk animasi */
    z-index: 3; /* Pastikan elemen h1 berada di atas elemen gambar */
    color: rgb(255, 255, 255);
    font-size: 14pt;
    font-weight: bold;
}

.card-desawisata .content-card div .subtitle-card {
    opacity: 0; /* Set opasitas awal ke 0 */
    transition: opacity 0.5s ease; /* Transisi untuk animasi */
    z-index: 3; /* Pastikan elemen h1 berada di atas elemen gambar */
    color: rgb(255, 255, 255);
    font-weight: 600;
    font-size: 10pt;
}

.card-desawisata.fadeAnimasiUp {
    animation: fadeAnimasiUp 1s ease forwards;
}

.card-desawisata.fadeAnimasiUp .content-card .title-card, .card-desawisata.fadeAnimasiUp .content-card .subtitle-card{
    animation: fadeAnimasiUp 1s ease forwards;
}

.card-desawisata:hover img {
    transform: scale(1.1);
}

/* Wisata */
.cover {
    position: relative;
    padding: 0 10%;
    box-sizing: border-box;
}

.left {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 25px;
    border-radius: 50%;
    position: absolute;
    left: 9%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    z-index: 1;
}

.right {
    height: 50px;
    width: 50px;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    border-radius: 50%;
    right: 9%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
}

.scroll-images {
    position: relative;
    width: 100%;
    padding: 40px 0px;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.scroll-images-kuliner {
    position: relative;
    width: 100%;
    padding: 40px 0px;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.scroll-images-penginapan {
    position: relative;
    width: 100%;
    padding: 40px 0px;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.child {
    opacity: 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    min-width: 288px;
    height: 420px;
    margin: 0px 8px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-top 0.5s ease;
}

.child.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child:hover {
    transform: translateY(-1px);
    margin-top: -10px;
    cursor: pointer;
}

.child .cover-img {
    width: 100%;
    height: 288px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.child .text-child{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 125px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.child .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

.child-kuliner {
    opacity: 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    width: 288px;
    height: 370px;
    margin: 0px 8px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-top 0.5s ease;
}

.child-kuliner.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-kuliner:hover {
    transform: translateY(-1px);
    margin-top: -10px;
    cursor: pointer;
}

.child-kuliner .cover-img {
    width: 100%;
    height: 288px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.child-kuliner .text-child{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 120px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.child-kuliner .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

.cover-kuliner-page {
    position: relative;
    padding: 0px 40px;
    height: auto;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-height: 60vh;
}
.cover-kuliner-page-notfound {
    width: 745px;
    min-height: 60vh;
}


.child-cardkuliner {
    opacity: 0;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    width: 745px;
    /* height: 350px; */
    margin: 8px 0px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-right 0.5s ease, margin-left 0.5s ease;

}
.child-cardkuliner-loading {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    width: 745px;
    height: 200px;
    margin: 8px 0px;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;

}

.child-cardkuliner.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-cardkuliner:hover {
    transform: translateX(-1px);
    margin-right: -10px;
    margin-left: 10px;
    cursor: pointer;
}

.child-cardkuliner .cover-img {
    width: 200px;
    height: 200px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #00A2AD;
}

.child-cardkuliner .text-child{
    width: 80%;
    flex-direction: column;
    height: 150px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
    
}

.child-cardkuliner .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}


.child-cardPenginapan {
    opacity: 0;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    width: 745px;
    /* height: 350px; */
    margin: 8px 0px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-right 0.5s ease, margin-left 0.5s ease;

}
.child-cardPenginapan-loading {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    width: 745px;
    height: 200px;
    margin: 8px 0px;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;

}

.child-cardPenginapan.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-cardPenginapan:hover {
    transform: translateX(-1px);
    margin-right: -10px;
    margin-left: 10px;
    cursor: pointer;
}

.child-cardPenginapan .cover-img {
    width: 200px;
    height: 200px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #00A2AD;
}

.child-cardPenginapan .text-child{
    width: 80%;
    flex-direction: column;
    height: 150px;
    padding: 1px 15px 1px 15px;
    box-sizing: border-box;
    justify-content: space-between;
    
}

.child-cardPenginapan .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

.child-penginapan {
    opacity: 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    width: 288px;
    height: 420px;
    margin: 0px 8px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-top 0.5s ease;
}

.child-penginapan.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-penginapan:hover {
    transform: translateY(-1px);
    margin-top: -10px;
    cursor: pointer;
}

.child-penginapan .cover-img {
    width: 100%;
    height: 288px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.child-penginapan .text-child{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.child-penginapan .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

.scroll-images::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
}

.scroll-images::-webkit-scrollbar-thumb {
    background-color: black;
}


/* Wisata */
.cover {
    position: relative;
    padding: 0 10%;
    box-sizing: border-box;
}

.scroll-paket {
    position: relative;
    width: 100%;
    padding: 40px 0px;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.child-paket {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    align-items: center;
    min-width: 288px;
    height: 350px;
    margin: 0px 8px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-top 0.5s ease;
}

.child-paket.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-paket:hover {
    transform: translateY(-1px);
    margin-top: -10px;
    cursor: pointer;
}

.child-paket .text-child-paket{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.child-paket .cover-img {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.child-paket .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

.scroll-paket::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #aaa;
}

.scroll-paket::-webkit-scrollbar-thumb {
    background-color: black;
}


@media (max-width: 1440px) {
    .child, .child-paket{
        min-width: 272px;
    }
    .child .cover-img{
        max-height: 250px; 
    }
}


.container-image {
    position: relative;
    width: 20%;
  }
  
  .mySlides {
    display: none;
  }
  
  .mySlides img {
    width: 100%;
    height: 100%;
  }
  
  .mySlides .numbertext {
    position: absolute;
    top: 8px;
    left: 16px;
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  .prev {
    left: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .next {
    right: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Caption text */
  .caption-container {
    opacity: 0;
    display: flex;
    text-align: center;
    justify-content: center;
    background-color: #222222c2;
    padding: 2px 16px;
    color: white;
    position: absolute;
    bottom: 8px;
    width: 100%;
  }

  .container-image:hover{
    .caption-container{
        opacity: 1;
        cursor: pointer;
    }
  }
  
  /* Style for active image */
  .active {
    display: block;
  }

  .card-kamar{
    display: flex;
    margin-bottom: 1rem;
    padding: 2rem;
    -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.041);
    box-shadow: 0px 0px 0.5px 1.5px rgba(0, 0, 0, 0.09);
    border-radius: 15px;
  }
  
.cover-recomend{
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0 10%;
    min-height: 50vh;
}

.cover-recomended-item{
    display: flex;
    flex-wrap: wrap;
}

.recomended-badge{
    margin: 1rem;
    padding: 5px 12px;
    position: absolute;
    background-color: #00A2AD;
    color: #fff;
    border-radius: 6px;
}

.child-recomendasi {
    opacity: 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    width: 280px;
    height: 420px;
    margin: 0px 8px;
    border: 1px solid #f1f1f1;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 10%);
    transition: transform 0.5s ease, margin-top 0.5s ease;
    margin-bottom: 1rem;
    align-items: flex-start;
}

.child-recomendasi.animasi {
    animation: bounceIncard 1s ease forwards;
}

.child-recomendasi:hover {
    transform: translateY(-1px);
    margin-top: -10px;
    cursor: pointer;
}

.child-recomendasi .cover-img {
    width: 100%;
    height: 288px; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.child-recomendasi .text-child{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 110px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
    justify-content: space-between;
}

.child-recomendasi .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}