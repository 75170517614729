/* styles.css */
/* LoginModal.css */

@keyframes fadeAnimasi {
  from {
      transform: translateY(-20px);
      opacity: 0; /* Awal transparan */
  }
  to {
      transform: translateY(0);
      opacity: 1; /* Akhir solid */
  }
}


.modal {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9998;
}

.modal.open {
  display: flex;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  border-radius: 15px;
  box-sizing: border-box;
}

.modal-content.animasiUp {
  animation: slideUp 0.2s forwards;
  /* Animasi dari bawah ke atas saat tampil */
}

.modal-content.animasiDown {
  animation: slideDown 0.2s forwards;
  /* Animasi dari bawah ke atas saat tampil */
}

.form {
  width: 100%;
  box-sizing: border-box;
}

.group-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgb(194, 194, 194);
  padding: 10px;
  border-radius: 10px;
}

.group-form input {
  outline: 0;
  width: 100%;
  border: 0;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.form-profile {
  width: 100%;
  box-sizing: border-box;
}

.group-form-profile {
  opacity: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid rgb(194, 194, 194);
  padding: 10px;
  border-radius: 10px;
  animation: fadeAnimasi 1s ease forwards;
}

.group-form-profile input {
  outline: 0;
  width: 100%;
  border: 0;
  padding: 5px;
  box-sizing: border-box;
  font-size: 14px;
}

.button-form {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  outline: 0;
  background-color: #00A2AD;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.otp-input-container input {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Tambahkan animasi untuk tombol close */
.cover-close {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  transition: transform 0.3s ease;
  /* Tambahkan transition untuk efek animasi */
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  transform: translateY(-3px);
  /* Geser ke atas saat hover */
}

.button-form:hover,
.button-form:focus {
  text-decoration: none;
  cursor: pointer;
  transform: translateY(-3px);
  /* Geser ke atas saat hover */
}


.alert{
  padding: 20px 40px;
  min-width: 420px;
  position: fixed;
  right: 0;
  top: 25px;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
}

.alert.warning{
  border-left: 8px solid #ffa502;
  background: #ffdb9b;
}
.alert.error{
  border-left: 8px solid #ce0000;
  background: #ff7b7b;
}
.alert.success{
  border-left: 8px solid #00ce64;
  background: #9eff9b;
}
.alert.info{
  border-left: 8px solid #00b9ce;
  background: #5cedfd;
}
.alert.showAlert{
  opacity: 1;
  pointer-events: auto;
}
.alert.show{
  animation: show_slide 1s ease forwards;
}
@keyframes show_slide {
  0%{
    transform: translateX(100%);
  }
  40%{
    transform: translateX(-10%);
  }
  80%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-10px);
  }
}
.alert.hide{
  animation: hide_slide 1s ease forwards;
}
@keyframes hide_slide {
  0%{
    transform: translateX(-10px);
  }
  40%{
    transform: translateX(0%);
  }
  80%{
    transform: translateX(-10%);
  }
  100%{
    transform: translateX(100%);
  }
}
.alert .fa-exclamation-circle{
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #ce8500;
  font-size: 30px;
}
.alert .fa-exclamation-circle.error{
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #ce0000;
  font-size: 30px;
}
.alert .fa-circle-check{
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #00ce64;
  font-size: 30px;
}
.alert .fa-circle-info{
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #00b9ce;
  font-size: 30px;
}
.alert.warning .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #ce8500;
}
.alert.error .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #ce0000;
}
.alert.info .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #00b9ce;
}
.alert.success .msg{
  padding: 0 20px;
  font-size: 18px;
  color: #00ce64;
}
.alert.warning .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #ffd080;
  padding: 20px 18px;
  cursor: pointer;
}
.alert.warning .close-btn:hover{
  background: #ffc766;
}
.alert.warning .close-btn .fas{
  color: #ce8500;
  font-size: 22px;
  line-height: 40px;
}


.alert.error .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #fc5656;
  padding: 20px 18px;
  cursor: pointer;
}
.alert.error .close-btn:hover{
  background: #f84343;
}
.alert.error .close-btn .fas{
  color: #ce0000;
  font-size: 22px;
  line-height: 40px;
}

.alert.info .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #14e6fd;
  padding: 20px 18px;
  cursor: pointer;
}
.alert.info .close-btn:hover{
  background: #11d9ef;
}
.alert.info .close-btn .fas{
  color: #00b9ce;
  font-size: 22px;
  line-height: 40px;
}

.alert.success .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #00ce64;
  padding: 20px 18px;
  cursor: pointer;
}
.alert.success .close-btn:hover{
  background: #66ffa3;
}
.alert.success .close-btn .fas{
  color: #00ce64;
  font-size: 22px;
  line-height: 40px;
}
.alert.success .close-btn{
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  background: #16ff87;
  padding: 20px 18px;
  cursor: pointer;
}
.alert.success .close-btn:hover{
  background: #1dee82;
}
.alert.success .close-btn .fas{
  color: #00ce64;
  font-size: 22px;
  line-height: 40px;
}
.jumlah_add{
  font-size: 12pt;
  margin: 1rem 0rem;
  width: 35%;
  display: flex;
  justify-content: space-around;
  border-radius: 7px;
  border: 1px solid #888;
  padding: 5px 5px;
}
.jumlah_add span{
  font-size: 10pt;
  padding: 2px 2px;
  background-color: #fff;
  cursor: pointer;
}
.jumlah_add input:focus{
  outline: none;
}

.date-style{
  margin: 1rem 0rem;
  border: 1px solid #aaa;
  width: 70%;
  padding: 5px 10px;
  border-radius: 7px;
}

.date-style:focus{
  outline: none;
}

.item-menu {
    display: flex;
    border-radius: 10px;
    align-items: center;
    width: 288px;
    box-sizing: border-box;
}

.item-menu .cover-img {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 10px;
}

.item-menu .text-item-menu{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 125px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;
}

.item-menu .cover-img img{
    top: 0; /* Geser gambar ke atas */
    left: 0; /* Geser gambar ke kiri */
    width: 100%; /* Atur lebar gambar agar sesuai dengan ukuran parent */
    height: 100%; /* Atur tinggi gambar agar sesuai dengan ukuran parent */
    object-fit: cover; /* Jaga proporsi gambar */
}

@keyframes slideUp {
  from {
    transform: translateY(200%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(300%);
  }
}