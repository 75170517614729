html{
  min-width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form{
  display: flex;
  flex-direction: column;
}

.w3-bar-item {
  display: flex;
  float: left;
  font-size: 12pt;
  font-weight: 500;
  color: #64646E;
  text-decoration: none;
  transition: all .3s ease-in-out;
  margin-right: 1rem;
  margin-top: 2rem;
  width: 150px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: none;
}
.w3-bar-item.active{
  color: #00A2AD;
  background-color: #dbf1f3;
}

.budge-default {
  display: flex;
  font-size: 10pt;
  color: #00A2AD;
  background-color: #dbf1f3;
  text-decoration: none;
  transition: all .3s ease-in-out;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: none;
  padding: 5px;
  margin-right: 10px;
}

.w3-bar-item:hover {
  color: #00A2AD;
}

.w3-container{
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 3rem;
  border: 1px solid grey;
  border-radius: 25px;
  box-sizing: border-box;
  justify-content: start;
}

.container-fasilitas{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 25px;
  box-sizing: border-box;
  justify-content: flex-start;
}

.item-fasilitas{
  min-width: calc(25.5% - 10px);
  margin: 2rem 0rem;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: 0 0 0 5px; 
  width: 15px;
  height: 15px;
  
  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}
.spinner.black{
  
  & .path {
    stroke: #000000;
  }
  
}

.spinner-only {
  animation: rotate 2s linear infinite;
  z-index: 2;
  margin: 0 0 0 5px; 
  width: 40px;
  height: 40px;
  
  & .path {
    stroke: #fff;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
}

.spinner-only.black{
  & .path {
    stroke: #000;
  }
  
}

.spinner-only.secondary{
  & .path {
    stroke: #d3d3d3;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}


.navbars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
  transition: background-color 0.3s ease;
  background-color: transparent; /* Latar belakang transparan */
  box-sizing: border-box;
  z-index: 999;
}

.navbars.scrolled {
  background-color: #fff;
  box-shadow: 1px 1px 1px rgb(221, 221, 221);
}

ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin-left: 20px;
}

.nav-items{
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
  font-weight: 500;
}

.nav-items:hover {
  color: #00A2AD;
  cursor: pointer;
}

.nav-items.scrolled{
  color: #000000;
}

.nav-items.scrolled:hover {
  color: #00A2AD;
}


.footers{
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  background-color: #2C2C2C;
  min-height: 350px;
  padding: 4rem 10%;
  box-sizing: border-box;
}

.content-footer, .cover-img {
  width: 45%;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

a:hover{
  cursor: pointer;
}

.button-masuk{
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #fff;
  padding: 5px 25px;
}

.button-masuk.scrolled{
  border: 1px solid #000000;
}

.button-masuk:hover{
  color: #00A2AD;
  border: 1px solid #00A2AD;
  cursor: pointer;
}

.button-daftar{
  text-decoration: none;
  color: #ffffff;
  border: 1px solid #00A2AD;
  background-color: #00A2AD;
  padding: 5px 25px;
}

.button-daftar:hover{
  border: 1px solid #cecece;
  background-color: #cecece;
  cursor: pointer;
}
.btn-detail{
  background-color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 10pt;
  color: #00A2AD;
}


.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}

.align-item-center {
  align-items: center;
}
.align-item-end{
  align-items: end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-beetwen {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}

.text-white {
  color: white;
}
.text-default {
  color: #00A2AD;
}
.text-black {
  color: rgb(0, 0, 0);
}
.text-secondary {
  color: gray;
}
.text-danger {
  color: red;
}
.text-warning {
  color: yellow;
}

.bg-default {
  background-color: #00A2AD;
}
.bg-black {
  background-color: rgb(0, 0, 0);
}
.bg-secondary {
  background-color: gray;
}
.bg-danger {
  background-color: red;
}
.bg-warning {
  color: yellow;
}

.text-size-24{
  font-size: 24pt;
}.text-size-20{
  font-size: 20pt;
}.text-size-18{
    font-size: 18pt;
}.text-size-16{
  font-size: 16pt;
}.text-size-14{
  font-size: 14pt;
}.text-size-12{
  font-size: 12pt;
}.text-size-10{
  font-size: 10pt;
}.text-size-9{      
  font-size: 9pt;
}.text-size-8{
  font-size: 8pt;
}.text-size-7{
  font-size: 7pt;
}.text-size-6{
  font-size: 6pt;
}.text-size-5{
  font-size: 5pt;
}.text-size-4{
  font-size: 4pt;
}

.text-coret {
  text-decoration: line-through;
}

.text-bold{
  font-weight: bold;
}

.bg-white {
  background-color: white;
}

.under-line{
  border-bottom: 1px solid #edebeb;
}

.p-5 {
  padding: 5rem;
}
.p-4 {
  padding: 4rem;
}
.p-3 {
  padding: 3rem;
}
.p-2 {
  padding: 2rem;
}
.p-1 {
  padding: 1rem;
}
.py-5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-bottom-5 {
  padding-bottom: 5rem;
}
.py-bottom-4 {
  padding-bottom: 4rem;
}
.py-bottom-3 {
  padding-bottom: 3rem;
}
.py-bottom-2 {
  padding-bottom: 2rem;
}
.py-bottom-1 {
  padding-bottom: 1rem;
}
.px-5 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-4 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-3 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-left-5 {
  padding-left: 5rem;
}
.px-left-4 {
  padding-left: 4rem;
}
.px-left-3 {
  padding-left: 3rem;
}
.px-left-2 {
  padding-left: 2rem;
}
.px-left-1 {
  padding-left: 1rem;
}
.px-top-5 {
  padding-top: 5rem;
}
.px-top-4 {
  padding-top: 4rem;
}
.px-top-3 {
  padding-left: 3rem;
}
.px-top-2 {
  padding-top: 2rem;
}
.px-top-1 {
  padding-top: 1rem;
}

.m-5 {
  margin: 5rem;
}
.m-4 {
  margin: 4rem;
}
.m-3 {
  margin: 3rem;
}
.m-2 {
  margin: 2rem;
}
.m-1 {
  margin: 1rem;
}
.my-5 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-top-5 {
  margin-top: 5rem;
}
.my-top-4 {
  margin-top: 4rem;
}
.my-top-3 {
  margin-top: 3rem;
}
.my-top-2 {
  margin-top: 2rem;
}
.my-top-1 {
  margin-top: 1rem;
}
.my-top-0 {
  margin-top: 0;
}
.my-bottom-5 {
  margin-bottom: 5rem;
}
.my-bottom-4 {
  margin-bottom: 4rem;
}
.my-bottom-3 {
  margin-bottom: 3rem;
}
.my-bottom-2 {
  margin-bottom: 2rem;
}
.my-bottom-1 {
  margin-bottom: 1rem;
}
.mx-5 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.mx-4 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-3 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-right-1 {
  margin-right: 1rem;
}
.mx-right-2 {
  margin-right: 2rem;
}
.mx-left-1 {
  margin-left: 1rem;
}
.mx-left-2 {
  margin-left: 2rem;
}

.my-bottom-1 {
  margin-bottom: 1rem;
}
.my-bottom-2 {
  margin-bottom: 2rem;
}
.my-bottom-3 {
  margin-bottom: 3rem;
}
.my-bottom-4 {
  margin-bottom: 4rem;
}

.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}
.w-70 {
  width: 70%;
}
.w-60 {
  width: 60%;
}
.w-50 {
  width: 50%;
}
.w-45 {
  width: 45%;
}
.w-30 {
  width: 30%;
}
.w-20 {
  width: 20%;
}
.w-10 {
  width: 10%;
}

.border-none {
  border: 0;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-15 {
  border-radius: 15px;
}
.rounded-25 {
  border-radius: 25px;
}
.rounded-50 {
  border-radius: 50px;
}
.rounded-circle {
  border-radius: 25%;
}

.title {
  padding: 1rem;
  font-size: 24pt;
  font-weight: bold;
}
.subtitle {
  font-size: 15pt;
  font-weight: 600;
}

.title-website{
  font-size: 18pt;
  font-weight: 700;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 3rem;
}

li {
  list-style-type: none;
  position: relative;
  padding: 0.625rem 0 0.5rem;
}
li ul {
  flex-direction: column;
  position: absolute;
  background-color: white;
  align-items: flex-start;
  transition: all 0.5s ease;
  width: 20rem;
  right: -3rem;
  top: 4.5rem;
  border-radius: 0.325rem;
  gap: 0;
  padding: 1rem 0rem;
  opacity: 0;
  box-shadow: 0px 0px 100px rgba(20, 18, 18, 0.25);
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.card-random{
  display: flex;
  -webkit-box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.041);
  box-shadow: 0px 0px 0.5px 1.5px rgba(0, 0, 0, 0.09);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.801);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-backdrop {
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #FFFFFF;
}

.slider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slider-image {
  max-width: 100%;
  max-height: 80vh;
  margin: 0 auto;
}

.slider-button {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  color: rgba(0, 0, 0, 0.836);
  align-items: center;
  justify-content: center;
}

.slider-button.left {
  left: 10px;
}

.slider-button.right {
  right: 10px;
}



.material-icons-outlined {
  color: #888888;
  transition: all 0.3s ease-out;
}

.material-icons-outlined:hover {
  color: #ff9800;
  transform: scale(1.25) translateY(-4px);
  cursor: pointer;
}


.profile {
  height: 3rem;
  width: auto;
  cursor: pointer;
}

.sub-item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.725rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
}

.sub-item:hover {
  background-color: rgba(232, 232, 232, 0.4);
}

.sub-item:hover .material-icons-outlined {
  color: #ff9800;
  transform: scale(1.08) translateY(-2px);
  cursor: pointer;
}

.sub-item:hover p {
  color: #000;
  cursor: pointer;
}

.sub-item p {
  font-size: 0.85rem;
  color: #888888;
  font-weight: 500;
  margin: 0.4rem 0;
  flex: 1;
}

.button-price {
  display: flex;
  justify-content: center;
  font-weight: 700;
  outline: 0;
  background-color: #00A2AD;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.button-price:hover {
  text-decoration: none;
  cursor: pointer;
  transform: translateY(-3px);
  /* Geser ke atas saat hover */
} 

/* display */
.show-hide{
  display: none;
}

.dropdown-item:hover{
  cursor: pointer;
}